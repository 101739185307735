<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Detail "{{ farm.name }}"
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container :data-farm="farm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    BreadCrumb
  },
  data() {
    return {
      name: 'Peternakan',
      status: 'Detail',
      breadcrumb: [
        {
          link: '/admin/peternakan',
          title: 'Peternakan'
        },
        {
          link: null,
          title: 'Detail'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      farm: 'farm/farm'
    })
  },
  async mounted() {
    await this.getFarmDetail(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getFarmDetail: 'farm/getFarmDetail'
    })
  }
}
</script>
