<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-width-small">
              <span class="uk-text-bold">Kode Kandang</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Nama Kandang</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Anak Kandang</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Tipe Kandang</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Siklus Ke</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Tahapan</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Aksi</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="cageFarms.meta.total_count>0">
            <tr
              v-for="(cage, i) in cageFarms.data"
              :key="i"
            >
              <td>{{ cage.cage_code || '-' }}</td>
              <td>{{ cage.name || '-' }}</td>
              <td>{{ cage.cage_staff || '-' }}</td>
              <td>{{ cage.cage_category || '-' }}</td>
              <td>{{ cage.log_cycle || 0 }}</td>
              <td>{{ cage.log_activity || '-' }}</td>
              <td>
                <img
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="img-click"
                  @click="showDetail(cage.id)"
                >
                <!-- <img v-lazy="`${images}/icon/visibility.svg`" alt="" class="uk-margin-right img-click" @click="showDetailModal(role.id)">
                <img v-if="role.name_employee_role !== 'Manager Divisi'" v-lazy="`${images}/icon/delete.svg`" alt="" class="img-click" @click="showDeleteConfirmModal(employee)"> -->
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="7"
          />
        </template>
        <template v-else>
          <loading-table :colspan="7" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="cageFarms.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Pagination from '@/components/globals/Pagination'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { STATUS } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    idFarm: {
      required: true
    }
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE,
      metaFilter: {
        farm_id: ''
      }
    }
  },
  computed : {
    ...mapGetters({
      cageFarms: 'cage/cageFarms',
      meta: 'cage/metaCageFarm'
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getCageFarm(this.meta)
      this.is_loading = false
    },
    async idFarm() {
      this.metaFilter.farm_id = this.idFarm
      await this.handleTableFind()
    }
  },
  async mounted() {
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    ...mapActions({
      getCageFarm: 'cage/getCageFarm'
    }),
    ...mapMutations({
      setMeta: 'cage/SET_META_CAGE_FARM',
      setModalDelete: 'user/SET_MODAL_DELETE'
    }),
    async handleTableFind () {
      this.setMeta({
        ...this.meta,
        ...this.metaFilter
      })
    },
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showDetail(id) {
      this.$router.push(`/admin/kandang-ayam/detail/${id}`)
    }
  }
}
</script>
